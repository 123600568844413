import { getTrainings } from "../../api/trainings";
import { requestStatus } from "../../services/index";

export default {
  namespaced: true,
  state: {
    training: null,
  },
  getters: {
    training: (state) => state.training,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    async getTrainings({ commit }, params) {
      try {
        const response = await getTrainings(params);

        if (response.status === requestStatus.success) {
          if (response && response.response && response.response.data) {
            commit("set", {
              type: "training",
              items: response.response.data,
            });
            return true;
          } else {
            commit("set", {
              type: "training",
              items: null,
            });
            return false;
          }
        }

        return false;
      } catch (error) {
        console.error("Error in getTrainings:", error);
        return false; // Request failed due to an error
      }
    },
    async clearTraining({ commit }) {
      commit("set", {
        type: "training",
        items: null,
      });
    },
  },
};
